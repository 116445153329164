<template>
	<div class="withdraw_box">
		<el-breadcrumb class="bread" separator="/">
			<el-breadcrumb-item :to="{ path: '/admin/dashboard' }">
				<div class="box">
					<div class="icon_box">
						<el-icon> <ArrowLeftBold /></el-icon>
					</div>
					<span>{{ $t('card_topup.breadcrumb1') }}</span>
				</div>
			</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/admin/cards' }">
				{{ $t('card_topup.breadcrumb2') }}
			</el-breadcrumb-item>
			<el-breadcrumb-item> {{ $t('card_topup.breadcrumb3') }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="withdraw">
			<div class="w_title">
				<div class="w_left">
					<div class="name">
						<span> {{ $t('card_topup.name') }}</span>
					</div>
					<div class="number">
						<span>{{ userData.usdtBalance }} </span>USDT
					</div>
				</div>
				<img src="../../assets/wallet/with_icon1.png" alt="" class="w_right" />
			</div>
			<div class="w_body">
				<el-form :model="state" label-width="auto" label-position="top" size="large">
					<el-form-item :label="$t('card_topup.label')">
						<el-select v-model="state.type" disabled>
							<template #label="{ label, value }">
								<img class="w_40" src="../../assets/wallet/wl_icon.png" alt="" />
								<span style="font-weight: bold">{{ value }}</span>
							</template>
							<el-option
								v-for="item in network"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('card_topup.label2')">
						<el-input v-model="state.money" @input="input_zz" />
					</el-form-item>
					<div class="tips">
						{{ $t('card_topup.tips1') }}
						<span v-if="state.money * 1 === userData.usdtBalance * 1">
							{{ state.money * 1 }}
						</span>
						<span v-else>
							{{
								state.money * 1 + state.money * state.feeInfo.virtualCardRechargeFeeRate * 0.01 ||
								0
							}}USD
						</span>
						<br />
						{{ $t('card_topup.tips2') }}
						<span v-if="state.money * 1 === userData.usdtBalance * 1">
							{{
								state.money * 1 - state.money * state.feeInfo.virtualCardRechargeFeeRate * 0.01 ||
								0
							}}USD
						</span>
						<span v-else> {{ state.money || 0 }}USD </span>
						<br />
						{{ $t('card_topup.tips3') }}
						{{ state.feeInfo.virtualCardRechargeFeeRate }}%
					</div>
				</el-form>
				<button class="button" @click="onSubmit">{{ $t('card_topup.button') }}</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { ArrowLeftBold, InfoFilled } from '@element-plus/icons-vue';
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import { userInfoApi, getFeeInfoApi, rechargeCardApi } from '@/api/index';

const router = useRouter();
const route = useRoute();

let state = reactive({
	type: 'OP Mainnet-USDT',
	money: '',
	feeInfo: {},
});

const network = [
	{
		value: 'OP Mainnet-USDT',
		label: 'OP Mainnet-USDT',
	},
];

const { locale } = useI18n();
const userData = ref({});

// api 请求
userInfoApi()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			userData.value = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
// api 请求
getFeeInfoApi()
	.then((res) => {
		// 关闭加载
		if (res.code == 200) {
			state.feeInfo = res.data;
		} else {
			ElMessage.error(res.msg);
		}
	})
	.catch(() => {
		// 关闭加载
	});
// const addressData = ref({});
// // api 请求
// getAddressApi()
// 	.then((res) => {
// 		// 关闭加载
// 		if (res.code == 200) {
// 			addressData.value = res.data;
// 		} else {
// 			ElMessage.error(res.msg);
// 		}
// 	})
// 	.catch(() => {
// 		// 关闭加载
// 	});
const input_zz = (value) => {
	// console.log(Number(value), 'value');
	// console.log(userData.value.usdtBalance * 1, 'usdtBalance');
	// console.log(Number(value) > userData.value.usdtBalance * 1, '>>>');
	if (value) {
		// state.money = value.replace(/[^\d]/g, 1);
		if (Number(value) <= 0) {
			state.money = 0;
		} else if (Number(value) >= userData.value.usdtBalance * 1) {
			state.money = userData.value.usdtBalance * 1;
		} else if (
			Number(value) + Number(value) * state.feeInfo.virtualCardRechargeFeeRate * 0.01 >=
			userData.value.usdtBalance * 1
		) {
			state.money = userData.value.usdtBalance * 1;
		}
	}
};

const onSubmit = () => {
	console.log(state.money);
	console.log(state.feeInfo.virtualCardRechargeMin);
	if (state.money * 1 < state.feeInfo.virtualCardRechargeMin * 1) {
		if (locale.value == 'en') {
			ElMessage.error('The current balance does not reach the minimum recharge amount');
		} else {
			ElMessage.error('当前余额未达到最少充值金额');
		}
	} else if (state.money > userData.value.usdtBalance) {
		if (locale.value == 'en') {
			ElMessage.error('The current recharge amount exceeds the account balance');
		} else {
			ElMessage.error('当前充值金额超过账户余额');
		}
	} else {
		const cost =
			state.money * 1 === userData.value.usdtBalance * 1
				? state.money * 1 - state.money * state.feeInfo.virtualCardRechargeFeeRate * 0.01
				: state.money * 1 + state.money * state.feeInfo.virtualCardRechargeFeeRate * 0.01;

		// 加载ui
		const loadingInstance1 = ElLoading.service({ fullscreen: true });
		const dz = cost > state.money ? state.money : cost;
		const cb = cost > state.money ? cost : state.money;

		console.log(dz, '用户到账', cb, '成本');
		// api 请求
		rechargeCardApi({
			cardId: route.query.cardId,
			rechargeAmount: dz, // 实际到账
			cost: cb, // 成本 到帐加属续费
		})
			.then((res) => {
				// 关闭加载
				loadingInstance1.close();
				if (res.code == 200) {
					if (locale.value == 'en') {
						ElMessage.success('Successful Topup');
					} else {
						ElMessage.success('充值成功');
					}
					router.go(-1);
				} else {
					ElMessage.error(res.msg);
				}
			})
			.catch(() => {
				// 关闭加载
				loadingInstance1.close();
			});
	}
};
</script>

<style scoped lang="scss">
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.withdraw_box {
		width: 70vw;
		min-height: 1288px;
		margin: 0 0 0 15px;
		display: flex;
		flex-direction: column;
		.bread {
			margin: 25px 10px;
			.box {
				display: flex;
				align-items: center;
				.icon_box {
					width: 22px;
					height: 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 2px solid #303133;
					border-radius: 50%;
					box-sizing: border-box;
					margin-right: 7px;
				}
			}
			.box:hover {
				cursor: pointer;

				.icon_box {
					border: 2px solid #409eff;
				}
			}
		}
		.withdraw {
			background: #ffffff;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			padding: 70px 0 133px;
			border-radius: 7px;
			border: 1px solid #e4e4e4;
			.w_title {
				padding: 25px 37px;
				width: 908px;
				height: 137px;
				border-radius: 7px;
				border: 1px solid #bbbbbb;
				background: #ffffff;
				display: flex;
				justify-content: space-between;
				margin-bottom: 52px;

				.w_left {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.name {
						font-size: 20px;
						color: #666666;
						// margin-bottom: 7px;
						span {
							margin-right: 12px;
						}
					}
					.number {
						font-size: 25px;
						span {
							font-size: 37px;
							color: #333333;
						}
					}
				}
				.w_right {
					width: 100px;
				}
			}
			.w_body {
				display: flex;
				flex-direction: column;
				background: #ffffff;
				width: 908px;
				// height: 601px;
				border-radius: 7px;
				border: 1px solid #bbbbbb;
				padding: 25px 37px 50px;
				.tips {
					width: 100%;
					height: 144px;
					background: #fff9e4;
					border-radius: 5px;
					border: 1px solid #ffd897;
					padding: 20px 26px;
					text-align: left;
					font-size: 21px;
					color: #333333;
					line-height: 35px;
					letter-spacing: 3px;
					margin-bottom: 55px;
				}
				.button {
					width: 100%;
					height: 62px;
					background: #333333;
					border-radius: 7px;
					color: #ffffff;
				}
			}
		}

		.w_40 {
			width: 30px;
			height: 30px;
			margin-right: 13px;
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
	}
}
</style>

<style lang="scss">
.w_body {
	.el-input__wrapper {
		border: 1px solid #666666;
	}
	.el-select__wrapper {
		border: 1px solid #666666;
	}
}
.el-select__selected-item {
	display: flex !important;
}
.el-breadcrumb {
	font-size: 22px;
	.el-icon {
		font-size: 14px !important;
	}
}
</style>
